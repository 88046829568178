(function(){
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    var isChrome = false;
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (isIOSChrome) {
        isChrome = true;
    } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        isChrome = true;
    }

    var nav_toggle = document.querySelector(".nav-toggle--button");
    nav_toggle.onclick = function () {
        document.getElementById("nav-main").classList.toggle("open");
        this.classList.toggle ("checked");
        if(document.getElementById("nav-lang").classList.contains("open")){
            document.getElementById("nav-lang").classList.remove("open");
        }
    };
    document.getElementById("nav-lang-toggle").onclick = function(e){
        e.stopPropagation();
        document.getElementById("nav-lang").classList.toggle("open");
        if(document.getElementById("nav-main").classList.contains("open")){
            document.getElementById("nav-main").classList.remove("open");
            document.querySelector(".nav-toggle--button").classList.remove("checked");
        }
    };
    document.body.onclick = function(){
        if(document.getElementById("nav-lang").classList.contains("open")){
            document.getElementById("nav-lang").classList.remove("open");
        }
    };

    let cookie_notice = document.getElementById("cookie-notice");
    if(cookie_notice !== null){
        if(!getCookie("cookies_accepted")){
            document.getElementById("cookie-notice").style.display = "block";
        }
        document.getElementById("accept-cookie").onclick = function(){
            cookie_notice.classList.add("fade-out");
            setCookie("cookies_accepted",true,7);
            window.setTimeout(function(){
                document.getElementById("cookie-notice").style.display = "none";
            },300);
        }
    }

    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return false;
    }

    if(document.querySelectorAll(".slider-tns").length > 0){
        let slider = tns({
            container: '.slider-tns',
            items: 1,
            slideBy: 'page',
            autoplay: true,
            autoplayHoverPause: true,
            controls: false,
            autoplayButtonOutput: false
        });
    }

    const header_videos = document.querySelectorAll(".header-video");
    if(header_videos.length > 0){
        document.getElementById("video").onclick = function(){
            if(this.paused){
                this.play();
            }else{
                this.pause();
            }
        };
        for(let i = 0; i < header_videos.length; i++){
            header_videos[i].querySelector(".video--mute").onclick = function(e){
                e.stopPropagation();
                if(document.getElementById("video").muted){
                    this.classList.remove("mute");
                    document.getElementById("video").muted = 0
                }else{
                    this.classList.add("mute");
                    document.getElementById("video").muted = 1
                }
            };
        }
    }

    //Animations
    const controller = new ScrollMagic.Controller();
    const revealModules = document.querySelectorAll(".reveal-container");
    for(let i = 0; i < revealModules.length; i++){
        const reverse = (revealModules[i].classList.contains("reveal-no-reverse") || revealModules[i].querySelectorAll(".reveal-horizontal").length > 0  ? false : true);
        new ScrollMagic.Scene({
            triggerElement: revealModules[i],
            offset: -300,
            reverse: reverse
        })
            .setClassToggle(revealModules[i], "reveal-active")
            .addTo(controller);
    }

    // get all slides
    const slides = document.querySelectorAll(".pinned");

    // create scene for every slide
    for (var i = 0; i< slides.length; i++) {
        new ScrollMagic.Scene({
            triggerElement: slides[i],
            triggerHook: 'onLeave',
            duration: '100%'
        })
            .setPin(slides[i], {pushFollowers: false})
            .addTo(controller);
    }

    function adjustViewportHeight(){
        const adjustHeight = document.querySelectorAll(".viewport-height");
        const viewportHeight = window.innerHeight;
        const navigationHeight = document.querySelector("nav").clientHeight;

        const targetHeight = viewportHeight;
        for(let i = 0; i < adjustHeight.length; i++){
            adjustHeight[i].style.height = targetHeight+"px";
            adjustHeight[i].style.paddingTop = navigationHeight+"px";
        }
    }
    adjustViewportHeight();

    function sameHeight() {
        let same_height_parents = document.querySelectorAll(".same-height");
        for (let i = 0; i < same_height_parents.length; i++) {
            let same_height_children = same_height_parents[i].querySelectorAll(".same-height-element");
            if (window.innerWidth > 768 || same_height_parents[i].classList.contains("same-height-mobile")) {
                let height = 0;
                for (let j = 0; j < same_height_children.length; j++) {
                    same_height_children[j].style.height = "auto";
                    if (height < same_height_children[j].clientHeight) {
                        height = same_height_children[j].clientHeight;
                    }
                }
                if (height !== 0) {
                    for (let j = 0; j < same_height_children.length; j++) {
                        same_height_children[j].style.height = height + "px";
                        if (same_height_children[j].classList.contains("same-height-line-height")) {
                            same_height_children[j].style.lineHeight = height + "px";
                        }
                    }
                }
            } else {
                for (let j = 0; j < same_height_children.length; j++) {
                    same_height_children[j].style.height = "auto";
                    if (same_height_children[j].classList.contains("same-height-line-height")) {
                        same_height_children[j].style.lineHeight = "normal";
                    }
                }
            }
        }
    }

    sameHeight();
    window.addEventListener('resize', adjustViewportHeight);
    window.addEventListener('resize', sameHeight);

    let orientation = (window.innerWidth > window.innerHeight ? "landscape" : "portrait");
    if(orientation == "landscape"){
        changeVideoSrc();
    }
    window.addEventListener('resize', function(){
        let newOrientation = (window.innerWidth > window.innerHeight ? "landscape" : "portrait");
        if(newOrientation !== orientation){
            orientation = newOrientation;
            changeVideoSrc();
            const videos = document.querySelectorAll("video");
            for(let i = 0; i < videos.length; i++){
                const loadPromise = videos[i].load();
                if (loadPromise !== undefined) {
                    loadPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                        .catch(error => {
                            // Auto-play was prevented
                            // Show paused UI.
                        });
                }
            }
        }
    });

    window.onload = function(){
        sameHeight();
    };

    function changeVideoSrc(){
        let video_src = document.getElementById("video-src");
        if(video_src != null){
            const temp = video_src.src;
            video_src.src = video_src.getAttribute("data-src");
            video_src.setAttribute("data-src", temp);
            if(isChrome || isFirefox){
                document.getElementById("video").load();
            }
        }
    }
})();